import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import getWeek from "date-fns/getWeek";
import isSameDay from "date-fns/isSameDay";
import isSameHour from "date-fns/isSameHour";
import sumBy from "lodash/sumBy";
import {
  Adjustment,
  CombinedData,
  CombinedRecord,
  DateRange,
  Forecast,
  ICombinedRecord,
  ICombinedRows,
  IFineGrainedForecast,
  IHourlyAdjustment,
  IHourlyForecastRows,
  MetaDataForecasts,
  MetaDataSales,
  PreviousForecast,
  Sale,
  Variance,
  Granularity,
} from "../types";
import { combinedDataAPIResponseType } from "../types/schemas";

const DATE_FORMAT_STRING = "dd/MM/yyyy EEE";
const DATE_FORMAT_STRING_HOURLY = "HH:mm";

const getDailyForecastRecords = (
  restaurantId: string,
  interval: { start: Date; end: Date },
  {
    adjustments,
    // import_metadata,
    forecasts,
    sales,
    variances,
    hourlyAdjustments,
  }: CombinedData,
): ICombinedRecord[] => {
  const dateInterval = eachDayOfInterval(interval);

  const records: ICombinedRecord[] = [];

  // Iterate through all dates in interval and generate records
  dateInterval.map((date) => {
    // Get all adjustments for day
    const adj = adjustments.filter((a) => isSameDay(a.businessDate, date));

    // Get latest adjustment for day
    const adj_latest = adj.reduce(
      (max, current) =>
        max.adjustmentId > current.adjustmentId ? max : current,
      // default value for empty Arrays
      new Adjustment(date, restaurantId),
    );

    // Get all forecasts for day
    const fc = forecasts.filter((f) => isSameDay(f.businessDate, date));

    // Get latest forecast for day
    const fc_latest = fc.reduce(
      (max, current) => (max.forecastId > current.forecastId ? max : current),
      new Forecast(date),
    );

    // Get all sales for day
    const sls = sales.filter((s) => isSameDay(s.businessDate, date));

    // Get latest sale for day
    const sls_latest = sls.reduce(
      (max, current) => (max.salesId > current.salesId ? max : current),
      new Sale(date, restaurantId),
    );

    // Get all variances for day
    const vr = variances.filter((s) => isSameDay(s.businessDate, date));

    // Get latest variance for day
    const vr_latest = vr.reduce(
      (max, current) => (max.varianceId > current.varianceId ? max : current),
      new Variance(date, restaurantId),
    );

    // Find latest forecast file import data
    // const latest_import_meta = import_metadata.forecasts.reduce(
    //   (max, current) =>
    //     max.forecastedDate > current.forecastedDate ? max : current,
    // );
    // const latest_import_date = latest_import_meta?.forecastedDate;

    const previousAdjustedForecast =
      fc.find((x) => x.forecastId === adj_latest.latestAdjustedForecastId) ||
      new Forecast(date);

    const previousForecast: PreviousForecast = {
      previous_forecastId: previousAdjustedForecast.forecastId,
      previous_businessDate: previousAdjustedForecast.businessDate,
      previous_eatIn: previousAdjustedForecast.eatIn,
      previous_delivery: previousAdjustedForecast.delivery,
      previous_collect: previousAdjustedForecast.collect,
      previous_total: previousAdjustedForecast.total,
    };

    const default_map = {
      latest_forecastId: fc_latest.forecastId,
      latest_businessDate: fc_latest.businessDate,
      latest_eatIn: fc_latest.eatIn,
      latest_delivery: fc_latest.delivery,
      latest_collect: fc_latest.collect,
      latest_total: fc_latest.total,
      collectOffset: 0,
      deliveryOffset: 0,
      eatInOffset: 0,
      totalOffset: 0,
      ...adj_latest,
      ...sls_latest,
      ...vr_latest,
      ...previousForecast,
    };

    const hourlyAdjustmentsForDay = hourlyAdjustments?.filter((adjustment) => {
      return isSameDay(adjustment.business_time, date);
    });

    if (hourlyAdjustmentsForDay) {
      const totalCollectOffset = sumBy(
        hourlyAdjustmentsForDay,
        (adjustment) => {
          return adjustment.channel === "collect" ? adjustment.value : 0;
        },
      );

      const totalDeliveryOffset = sumBy(
        hourlyAdjustmentsForDay,
        (adjustment) => {
          return adjustment.channel === "delivery" ? adjustment.value : 0;
        },
      );

      const totalEatInOffset = sumBy(hourlyAdjustmentsForDay, (adjustment) => {
        return adjustment.channel === "eatIn" ? adjustment.value : 0;
      });

      const totalOffset =
        totalCollectOffset + totalDeliveryOffset + totalEatInOffset;

      default_map.collectOffset = totalCollectOffset;
      default_map.deliveryOffset = totalDeliveryOffset;
      default_map.eatInOffset = totalEatInOffset;
      default_map.totalOffset = totalOffset;
    }

    records.push({
      ...default_map,
      ...fc_latest,
    });
  });

  return records;
};

const sumToWeekly = (daily: CombinedRecord[]): CombinedRecord[] => {
  return daily.reduce((acc, current) => {
    const businessDate = current.businessDate;
    const currentWeek = getWeek(businessDate, { weekStartsOn: 1 });
    const m = current.multiplier;
    const existingWeek: CombinedRecord | null =
      acc.find((w: CombinedRecord) => w.weekId === currentWeek) || null;
    if (existingWeek) {
      existingWeek.total += current.total * m;
      existingWeek.eatIn += current.eatIn * m;
      existingWeek.collect += current.collect * m;
      existingWeek.delivery += current.delivery * m;
      existingWeek.actual_total += current.actual_total;
      existingWeek.actual_eatIn += current.actual_eatIn;
      existingWeek.actual_collect += current.actual_collect;
      existingWeek.actual_delivery += current.actual_delivery;
    } else {
      acc.push({
        ...current,
        ...new Adjustment(businessDate, current.restaurantId),
        forecastId: current.forecastId,
        weekId: currentWeek,
        businessDate: businessDate,
        total: current.total * m,
        eatIn: current.eatIn * m,
        delivery: current.delivery * m,
        collect: current.collect * m,
        actual_total: current.actual_total,
        actual_eatIn: current.eatIn,
        actual_collect: current.collect,
        actual_delivery: current.delivery,
      });
    }
    return acc;
  }, [] as CombinedRecord[]);
};

const getHourlyRows = (
  restaurantId: string,
  date: Date,
  { forecasts, adjustments, sales, hourlyAdjustments }: CombinedData,
): IHourlyForecastRows[] => {
  const latestAdjustment = adjustments
    .filter((a) => isSameDay(a.businessDate, date))
    .reduce(
      (max, current) =>
        max.adjustmentId > current.adjustmentId ? max : current,
      new Adjustment(date, restaurantId),
    );

  const maxForecastId = Math.max(...forecasts.map((f) => f.forecastId));

  const multiplier = latestAdjustment.multiplier;

  if (multiplier !== 1) {
    forecasts = forecasts.map((forecast) => {
      forecast.eatIn = forecast.eatIn * multiplier;
      forecast.delivery = forecast.delivery * multiplier;
      forecast.collect = forecast.collect * multiplier;
      forecast.total = forecast.total * multiplier;
      return forecast;
    });
  }

  const filteredForecasts = forecasts
    .filter(
      (forecast) =>
        isSameDay(forecast.businessDate, date) &&
        forecast.forecastId === maxForecastId,
    )
    .map((hourlyForecast: Forecast) => {
      const sale = sales.find((sale) =>
        isSameHour(sale.businessDate, hourlyForecast.businessDate),
      );

      const fineGrainedHourlyRow = convertHourlyValueToObject(hourlyForecast);

      if (hourlyAdjustments) {
        const hourlyAdjustmentsForHour = hourlyAdjustments.filter(
          (adjustment) =>
            isSameHour(
              adjustment.business_time,
              fineGrainedHourlyRow.businessDate,
            ),
        );

        hourlyAdjustmentsForHour.forEach((adjustment) => {
          if (adjustment.channel === "collect") {
            fineGrainedHourlyRow.collect.isAdjusted = true;
            fineGrainedHourlyRow.collect.offsetValue = adjustment.value;
          } else if (adjustment.channel === "delivery") {
            fineGrainedHourlyRow.delivery.isAdjusted = true;
            fineGrainedHourlyRow.delivery.offsetValue = adjustment.value;
          } else if (adjustment.channel === "eatIn") {
            fineGrainedHourlyRow.eatIn.isAdjusted = true;
            fineGrainedHourlyRow.eatIn.offsetValue = adjustment.value;
          }
        });
      }

      const objectToReturn = {
        ...sale,
        ...latestAdjustment,
        ...fineGrainedHourlyRow,
      };

      return objectToReturn;
    });

  const forecastRows = filteredForecasts.map((forecast, index: number) => {
    return {
      ...forecast,
      id: index,
      formattedBusinessDate: format(
        forecast.businessDate,
        DATE_FORMAT_STRING_HOURLY,
      ),
      businessDate: forecast.businessDate,
    };
  });

  return forecastRows;
};

const mapToRows = (
  records: ICombinedRecord[],
  dateFormat: string,
): ICombinedRows[] => {
  const rows = records.map((x: ICombinedRecord, index: number) => ({
    ...x,
    id: index,
    formattedBusinessDate: format(x.businessDate, dateFormat),
    businessDate: x.businessDate,
  }));

  return rows;
};

const getRows = (
  restaurantId: string,
  interval: DateRange,
  data: combinedDataAPIResponseType,
  granularity: Granularity,
) => {
  if (granularity === "Hourly") {
    return getHourlyRows(
      restaurantId,
      interval.start,
      data as unknown as CombinedData,
    );
  } else {
    const recordsDaily = getDailyForecastRecords(
      restaurantId,
      interval,
      data as unknown as CombinedData,
    );
    if (granularity === "Weekly") {
      const recordsWeekly = sumToWeekly(recordsDaily);
      return mapToRows(recordsWeekly as ICombinedRecord[], DATE_FORMAT_STRING);
    }
    return mapToRows(recordsDaily, DATE_FORMAT_STRING);
  }
};

const dateAsUTC = (LOCAL: Date, timeZone = "Europe/London"): Date => {
  return utcToZonedTime(LOCAL, timeZone);
};

const dateAsReverseUTC = (UTC: Date, timeZone = "Europe/London"): Date => {
  return zonedTimeToUtc(UTC, timeZone);
};

const convertDates = (data: CombinedData): CombinedData => {
  const adjustments = data.adjustments.map(
    (a) =>
      ({
        ...a,
        businessDate: dateAsUTC(a.businessDate),
      }) as Adjustment,
  );

  const forecasts = data.forecasts.map(
    (f) =>
      ({
        ...f,
        businessDate: dateAsUTC(f.businessDate),
      }) as Forecast,
  );

  const forecasts_metadata = data.import_metadata.forecasts.map(
    (i) =>
      ({
        ...i,
        forecastedDate: dateAsUTC(i.forecastedDate),
      }) as MetaDataForecasts,
  );

  const sales = data.sales.map(
    (s) =>
      ({
        ...s,
        businessDate: dateAsUTC(s.businessDate),
      }) as Sale,
  );

  const variances = data.variances.map(
    (v) =>
      ({
        ...v,
        businessDate: dateAsUTC(v.businessDate),
      }) as Variance,
  );

  const sales_metadata = data.import_metadata.sales.map(
    (i) =>
      ({
        ...i,
        salesDate: dateAsUTC(i.salesDate),
      }) as MetaDataSales,
  );

  const hourlyAdjustments = data.hourlyAdjustments?.map(
    (hourlyAdjustment: IHourlyAdjustment) =>
      ({
        ...hourlyAdjustment,
        business_time: dateAsUTC(hourlyAdjustment.business_time),
      }) as IHourlyAdjustment,
  );

  return {
    adjustments,
    forecasts,
    import_metadata: { forecasts: forecasts_metadata, sales: sales_metadata },
    sales,
    variances,
    hourlyAdjustments,
  };
};

const convertHourlyValueToObject = (
  forecast: Forecast,
): IFineGrainedForecast => {
  return {
    ...forecast,
    eatIn: {
      originalValue: forecast.eatIn,
      isAdjusted: false,
      offsetValue: 0,
    },
    delivery: {
      originalValue: forecast.delivery,
      isAdjusted: false,
      offsetValue: 0,
    },
    collect: {
      originalValue: forecast.collect,
      isAdjusted: false,
      offsetValue: 0,
    },
  };
};

export { convertDates, dateAsReverseUTC, dateAsUTC, getRows, sumToWeekly };
